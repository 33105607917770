<script lang="ts" setup>
import type { DisplayMode } from '@shopware-pwa/composables-next';
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
import type { AlgoliaProduct } from '~/types/models/algoliaProduct';
import type { UseBreakpointsReturn } from '@vueuse/core';

export type ProductCardType = 'slider' | 'basic';

const props = withDefaults(
    defineProps<{
        product: AlgoliaProduct;
        displayMode?: DisplayMode;
        lazyLoadImg?: boolean;
        type?: ProductCardType;
        index?: number;
        categoryType?: string;
        fromListing?: boolean;
        preload?: boolean;
    }>(),
    {
        displayMode: 'standard',
        lazyLoadImg: true,
        type: 'basic',
        index: -1,
        categoryType: undefined,
        fromListing: false,
        preload: false,
    },
);

const emits = defineEmits<{
    (e: 'addWishlistProduct', productId: AlgoliaProduct): void;
}>();

// CARD handling
const { overlayVisible, isAvailable } = useQuantityValidation(props.product);

const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;

const { isLoggedIn } = useUser();

// showDetails flag is used for mobile viewports to show additional product infos in an accordion at the end of the product-box
const showDetails = ref(false);
provide('showDetails', showDetails);

const breakpoints: UseBreakpointsReturn = inject('breakpoints') as UseBreakpointsReturn;
const smallerThanMd = breakpoints.smaller('md');

const detailsLoaded = ref(false);
// hover flag is used for desktop viewports to show an overlay with additional product infos over the product-box
const boxWrapper = ref();
const showRestrictionMessage = ref(false);
const detailsOnHoverEnabled = computed(() => !smallerThanMd.value || props.type === 'slider');

const onMouseEnter = () => {
    if (!detailsOnHoverEnabled.value) return;
    showDetails.value = true;
};

const onMouseLeave = () => {
    if (!detailsOnHoverEnabled.value) return;
    showDetails.value = false;
};

if (import.meta.client) {
    watch(
        showDetails,
        () => {
            detailsLoaded.value = true;
        },
        { once: true },
    );
}

const onNotLoggedInClick = () => {
    showRestrictionMessage.value = !showRestrictionMessage.value;
};

const { page } = usePage();

// PRODUCT data
const { product } = toRefs(props);

const isArchiveArticle = computed(() => product.value?.isArchive);
const productNumber = computed(() => product.value.productNumber);

const { availabilities } = useAvailability();
const availability = computed(() => availabilities.value?.[productNumber.value] ?? []);

const { getPrice } = useProductPrices();
const priceInfo = computed(() => getPrice(productNumber.value));

const { listingBadges } = useAlgoliaProduct(product.value);
const { pathToSeoUrl } = useLanguageSelect();

const url = computed(() => {
    let completeUrl = pathToSeoUrl(product.value.url);

    if (props.fromListing && page.value?.category?.id) {
        completeUrl = completeUrl + '?c=' + page.value.category.id;
    }

    return completeUrl || '';
});

const { trackAlgoliaObjectClick, trackAlgoliaObjectAddToCart, currentPageQueryId } = useAlgoliaTracking();
const { productQueryId } = useLayoutStoreSearch();

const onProductClick = () => {
    trackAlgoliaObjectClick({
        objectIDs: [props.product.objectID],
        queryID: productQueryId.value || currentPageQueryId.value,
        positions: [props.index + 1],
    });
};

const onAddToCartSuccess = () => {
    trackAlgoliaObjectAddToCart({
        queryID: productQueryId.value || currentPageQueryId.value,
        objectIDs: [props.product.objectID],
    });
};
</script>

<template>
    <div
        :class="{ shadow: (showDetails && detailsOnHoverEnabled) || showRestrictionMessage }"
        class="rounded-md flex flex-col justify-between"
    >
        <div
            ref="boxWrapper"
            class="w-full flex relative pb-0 gap-4"
            :class="{
                'flex-col p-3.5': type === 'slider',
                'md:flex-col md:p-3.5': type !== 'slider',
            }"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
        >
            <div
                class="relative basis-1/2 md:basis-auto h-[150px] flex justify-center"
                :class="{ 'min-w-[150px] md:min-w-[240px] sm:min-w-[240px]': type === 'slider' }"
            >
                <NuxtLink
                    :title="$t('productDetailsLinkTo')"
                    :to="url"
                    class="inline-flex"
                    @click="onProductClick"
                >
                    <SharedImage
                        :alt="product.name"
                        :media-src="product.coverUrl || undefined"
                        class="w-auto max-h-full"
                        sizes="150px"
                        :width="150"
                        :height="150"
                        :lazy-load="lazyLoadImg"
                        :preload
                    />
                </NuxtLink>

                <div
                    v-if="listingBadges?.length"
                    class="absolute inline-flex gap-1 top-0 left-0 md:text-right md:left-auto md:right-2"
                >
                    <LazyProductBadges :badges="listingBadges" />
                </div>
            </div>

            <div class="basis-1/2 flex-col justify-between flex-1 md:basis-1/1">
                <div class="sm:relative">
                    <div class="mb-2.5 min-h-6.25">
                        <SharedLabel
                            v-if="product?.manufacturer"
                            color="gray-100"
                            size="lg"
                        >
                            {{ product.manufacturer }}
                        </SharedLabel>
                    </div>

                    <div class="text-base font-bold text-gray-700">
                        <NuxtLink
                            :title="$t('productDetailsLinkTo')"
                            :to="url"
                            class="line-clamp-2 min-h-8"
                            @click="onProductClick"
                        >
                            {{ product.name }}
                        </NuxtLink>
                    </div>

                    <div class="mt-2 text-sm text-gray-500 leading-4.5 min-h-9">
                        <NuxtLink
                            :title="$t('productDetailsLinkTo')"
                            :to="url"
                            class="line-clamp-2"
                            @click="onProductClick"
                        >
                            {{ product.description_short }}
                        </NuxtLink>
                    </div>

                    <Transition
                        enterActiveClass="ease-out duration-300"
                        enterFromClass="opacity-0"
                        enterToClass="opacity-100"
                        leaveActiveClass="ease-in duration-200"
                        leaveFromClass="opacity-100"
                        leaveToClass="opacity-0"
                    >
                        <div
                            v-if="overlayVisible"
                            class="absolute top-0 left-0 bg-white w-full z-1 shadow p-2 h-[111px] sm:shadow-none sm:p-0"
                        >
                            <SharedQuantityInfo class="mb-3" />
                        </div>
                    </Transition>
                </div>
                <div class="mt-2 min-h-12">
                    <ProductCardPrices
                        :product-number="productNumber"
                        :is-archive-article="isArchiveArticle"
                        context="listing"
                    />
                </div>
            </div>

            <template v-if="detailsLoaded">
                <div
                    v-show="showDetails && detailsOnHoverEnabled"
                    class="absolute top-0 left-0 bg-white w-full h-full z-1 p-3.5"
                >
                    <LazyProductCardDetails
                        :product="product"
                        :availability="availability"
                        :index="index !== -1 ? index : undefined"
                        :url="url"
                        :category-type="categoryType"
                    />
                </div>
            </template>

            <Transition
                enterActiveClass="transition-opacity"
                enterFromClass="opacity-0"
                enterToClass="opacity-100"
                leaveActiveClass="transition-opacity"
                leaveFromClass="opacity-100"
                leaveToClass="opacity-0"
            >
                <div
                    v-if="showRestrictionMessage"
                    class="absolute top-0 left-0 bg-white w-full h-full z-1 flex flex-col justify-between p-3.5 text-sm lg:text-base"
                >
                    <span
                        v-if="priceInfo?.unbuyableText"
                        v-html="priceInfo.unbuyableText"
                    />
                    <span v-else>{{ $t('productUnbuyableFallback') }}</span>
                    <div class="text-right font-bold mb-7">
                        {{ priceInfo?.unbuyableTitle }}
                    </div>
                </div>
            </Transition>
        </div>

        <div
            class="mt-3 flex gap-3 flex-row pt-0"
            :class="{
                'p-3.5': type === 'slider',
                'md:p-3.5': type !== 'slider',
            }"
        >
            <div class="flex flex-row items-center justify-between gap-1">
                <ClientOnly>
                    <LazyProductCardOverlayToggle
                        v-if="!detailsOnHoverEnabled"
                        :id="'product-box-overlay-' + product.productId"
                        :open="showDetails"
                        class="md:hidden"
                    />
                </ClientOnly>

                <LazySharedButton
                    v-if="!isArchiveArticle"
                    :data-testid="`open-modal-product-to-wishlist-trigger-${product.productId}`"
                    :link="true"
                    class="px-3"
                    color="transparent"
                    :class="{ '!text-gray-500': !isLoggedIn }"
                    :title="$t('wishlist.addProductTitle')"
                    @click.prevent="
                        !isLoggedIn
                            ? offcanvas.open('AccountLoginForm', 'layout-login-offcanvas')
                            : emits('addWishlistProduct', product)
                    "
                >
                    <LazySvgoThumbtack class="h-5 w-auto" />
                </LazySharedButton>

                <LazySharedDeliveryState
                    v-if="isLoggedIn && !isArchiveArticle"
                    class="p-3"
                    mode="badge"
                    :availability="availability"
                />
            </div>

            <div class="flex flex-row gap-1 justify-end grow-1">
                <div
                    v-if="!isArchiveArticle && isAvailable && isLoggedIn"
                    class="basis-1/2 max-w-[80px]"
                >
                    <LazySharedQuantityInput
                        :id="'card-quantity-' + product.productId"
                        data-testid="product-card-input"
                    />
                </div>

                <div
                    class="basis-1/2"
                    :class="isLoggedIn ? 'max-w-[80px]' : null"
                >
                    <LazySharedAddToCart
                        v-if="isLoggedIn"
                        :show-restriction-message="showRestrictionMessage"
                        :disabled="isArchiveArticle || !isAvailable"
                        @toggle-restriction-message="onNotLoggedInClick"
                        @add-to-cart-success="onAddToCartSuccess"
                    />
                    <LazySharedButton
                        v-else
                        color="gray-500"
                        :outline="true"
                        class="whitespace-nowrap py-2 !px-5 !text-base"
                        :title="$t('addToCartTitle')"
                        @click="!isLoggedIn ? offcanvas.open('AccountLoginForm', 'layout-login-offcanvas') : null"
                    >
                        {{ $t('productLoginBuyText') }}
                    </LazySharedButton>
                </div>
            </div>
        </div>

        <ClientOnly>
            <LazyProductCardOverlay
                v-if="detailsLoaded && !detailsOnHoverEnabled"
                :id="'product-box-overlay-' + product.productId"
                :open="showDetails"
            >
                <LazyProductCardDetails
                    :product="product"
                    :availability="availability"
                    :index="index !== -1 ? index : undefined"
                    :url="url"
                    :category-type="categoryType"
                />
            </LazyProductCardOverlay>
        </ClientOnly>
    </div>
</template>
